import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"

// import mcCarthyLogo from ''
import mcCarthyLogo from '../../images/sponsors/mccarthy-family-foundation-logo.jpg'
import bostonGlobeLogo from '../../images/sponsors/boston-globe.png'
import bostonGlobeFoundationLogo from '../../images/sponsors/boston-globe-foundation.png'
import westminsterCollegeLogo from '../../images/sponsors/westminster.png'
import westminsterStudents from '../../images/sponsors/westminster-college-students.jpg'
import westminsterInUtah from '../../images/sponsors/Westminster-in-Utah.jpg'


import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import LeftMenu from './leftMenu'
const Sponsors = () => (
  <Layout>
    <SEO title="National Student Essay Competition" />

    <div className="subpageContainer">

      <LeftMenu active="sponsors" />
      <div className="body">
         
        <h1 style={{marginTop: '55px'}}>Sponsors</h1>
        
        <p>The National Student Essay Competition is grateful to its exceptional sponsors.</p>

        <div className="sponsorGrid">


          <div className="logo">
            {/* <img src={mcCarthyLogo} alt="McCarthy Logo" /> */}
            <img style={{maxWidth: '175px'}} src={mcCarthyLogo} alt="Boston Globe Logo" />
          </div>

          <div>
            <p>The McCarthey Family Foundation is dedicated to supporting those causes that reflect the values of their legacy: private and public education, medical research, and historic preservation. Among the many organizations and institutions that have received funding are the American Diabetes Association, Cathedral of the Madeleine Choir School, Gonzaga University, Gonzaga University-in-Florence, Huntsman Cancer Institute, Judge Memorial Catholic High School, Kearns-St. Ann School, Multiple Sclerosis Society/Utah Chapter, Rowland Hall St. Mark’s School Campus, St. Ambroise Parish, St. Joseph’s Villa, University of Utah, Westminster College, and the YWCA.</p>
            <hr />
          </div>

          <div className="logo">
            <img style={{maxWidth: '300px'}} src={bostonGlobeLogo} alt="Boston Globe Logo" />
          </div>

          <div style={{paddingTop: "100px"}}>
          <hr />
          </div>

          <div className="logo">
            <img style={{maxWidth: '300px'}} src={bostonGlobeFoundationLogo} alt="Boston Globe Foundation Logo" />
          </div>

          <div style={{paddingTop: "100px"}}>
            <hr />
          </div>


          <div className="logo">
            <img style={{maxWidth: '150px'}} src={westminsterCollegeLogo} alt="Westminster College Logo" />
          </div>

          <div>
              <p>Westminster College in Salt Lake City, Utah, is pleased to offer a four-year grant (or “scholarship”) for the McCarthey Foundation’s essay winner to pursue a baccalaureate degree with a major of their choosing at the College. This award, currently valued at $38K a year, will cover the full cost of tuition and fees, Essay winners must qualify for admission to the college and submit a completed FAFSA application to be eligible for this scholarship.</p>
              <p>Westminster is a private, comprehensive college in Salt Lake City, Utah. A liberal arts education at Westminster is more than just earning a degree—it’s about learning how to thrive in diverse environments and opening your mind to possibilities. <a href="https://www.westminstercollege.edu/">https://www.westminstercollege.edu/</a></p>
              <div>
                <img src={westminsterInUtah} alt="Westminster in Utah" />
                <img src={westminsterStudents} alt="Westminster Students" />
              </div>
              <hr />
          </div>

        </div>
        
        


      </div>
    </div>

    

  </Layout>
)

export default Sponsors
